﻿import { Component, AfterViewInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, MayoService } from "../shared/common/index";

import "!svg-sprite-loader!element.ui/modules/assets/icons/material-design/contacts.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/material-design/phone2.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/computer.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/male-doctor.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/calendar-alt.svg";

@Component({
    selector: 'contact-component',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})


export class ContactComponent implements AfterViewInit {

    public constructor(
        private titleService: Title, private router: Router, private mayo: MayoService,
        @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {

        this.titleService.setTitle("FH Family Share - Contact");
    }

    ngAfterViewInit(): void {
        this.mayo.init();
    }
}
﻿import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {APP_BASE_HREF} from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { LayoutComponent } from "./layout.component";
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home.component';
import { LearnComponent } from './learn.component';
import { ShareComponent } from './share.component';
import { ContactComponent } from './contact.component';
import { PrintComponent } from './print.component';
import { FamilyTreeModule } from './family-tree/family-tree.module';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, SimpleModalComponent, LeaveSiteModalComponent, MayoService, IMayoService } from '../shared/common/index';
import { ModalTriggerDirective } from '../shared/common/modalTrigger.directive';
import { CustomerSupportComponent } from "./customer-support.component";
import { ToolsComponent } from "./tools.component";
import { LearnGeneticTestingFaqsComponent } from "./learn-genetic-testing-faqs/learn-genetic-testing-faqs.component";
import { HighlightDirective } from "./highlight.directive";
import { LearnWhatIsFhComponent } from "./learn-what-is-fh/learn-what-is-fh.component";
import { LearnLayoutComponent } from "./learn-layout/learn-layout.component";
import { LearnFhConsiderationsComponent } from "./learn-fh-considerations/learn-fh-considerations.component";
import { LearnGeneticsComponent } from "./learn-genetics/learn-genetics.component";
import { LearnWhatCausesFhComponent } from "./learn-what-causes-fh/learn-what-causes-fh.component";
import { LearnFhTitleComponent } from "./learn-fh-title/learn-fh-title.component";
import { LearnFhRisksComponent } from "./learn-fh-risks/learn-fh-risks.component";
import { LearnFhSymptomsComponent } from "./learn-fh-symptoms/learn-fh-symptoms.component";
import { LearnTreatmentComponent } from "./learn-treatment/learn-treatment.component";
import { LearnAdditionalResourcesComponent } from "./learn-additional-resources/learn-additional-resources.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

let toastr: Toastr = window['toastr'];
let jQuery = window['$'];

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'learn', component: LearnComponent,
        children: [
            { path: '', redirectTo: '/fh-title-page', pathMatch: 'full' },
            { path: 'fh-title-page', component: LearnFhTitleComponent },
            { path: 'what-is-fh', component: LearnWhatIsFhComponent },
            { path: 'fh-considerations', component: LearnFhConsiderationsComponent },
            { path: 'fh-causes', component: LearnWhatCausesFhComponent },
            { path: 'fh-risks', component: LearnFhRisksComponent },
            { path: 'fh-symptoms', component: LearnFhSymptomsComponent },
            { path: 'genetics', component: LearnGeneticsComponent },
            { path: 'genetic-testing-faqs', component: LearnGeneticTestingFaqsComponent },
            { path: 'treatment', component: LearnTreatmentComponent },
            { path: 'additional-resources', component: LearnAdditionalResourcesComponent }
        ]   
    },    
    { path: 'share', component: ShareComponent },
    { path: 'tools', component: ToolsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'customerSupport', component: CustomerSupportComponent },
    { path: 'print', component: PrintComponent }
];

@
NgModule({
    imports: [
        RouterModule.forRoot(routes),
        HttpClientModule,
        FamilyTreeModule,
        BrowserModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        CarouselModule,
        RouterModule.forRoot(routes,{
            useHash: true,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled', 
        })
       
    ],
    declarations: [
        LayoutComponent,
        HomeComponent,
        LearnComponent,
        ShareComponent,
        PrintComponent,
        ToolsComponent,
        ContactComponent,
        CustomerSupportComponent,
        SimpleModalComponent,
        LeaveSiteModalComponent,
        ModalTriggerDirective,
        LearnGeneticTestingFaqsComponent,
        LearnFhTitleComponent,
        LearnWhatIsFhComponent,
        LearnFhConsiderationsComponent,
        LearnWhatCausesFhComponent,
        LearnFhRisksComponent,
        LearnFhSymptomsComponent,
        LearnGeneticsComponent,
        LearnTreatmentComponent,
        LearnAdditionalResourcesComponent,
        LearnLayoutComponent,
        HighlightDirective
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        Title,
        MayoService,
        { provide: TOASTR_TOKEN, useValue: toastr },
        { provide: JQ_TOKEN, useValue: jQuery }
    ],
    bootstrap: [LayoutComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
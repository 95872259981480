import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-learn-what-causes-fh',
  templateUrl: './learn-what-causes-fh.component.html',
  styleUrls: ['./learn-what-causes-fh.component.scss']
})
export class LearnWhatCausesFhComponent implements OnInit {

  isCollapsed: boolean;
  isLdlrCollapsed = false;
  isApobCollapsed = false;
  isPcsk9Collapsed = false;
  constructor() { }

  ngOnInit() {
  }

  ldlrClicked(){
    this.isApobCollapsed = false;
    this.isPcsk9Collapsed = false;
  }

  apobClicked(){
    this.isLdlrCollapsed = false;
    this.isPcsk9Collapsed = false;

  }
  pcsk9Clicked(){
    this.isLdlrCollapsed = false;
    this.isApobCollapsed = false;
  }

}

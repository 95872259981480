﻿import { InjectionToken, Injectable, Inject } from "@angular/core";
import { JQ_TOKEN, } from "./jQuery.service";

export interface IMayoService {
    init(): void;
    tab(id: string): void;
    scroll(id: string): void;
    carousel(id: string): void;
    getDate(): Date;
    print(): void;
}

export class MayoService implements IMayoService {

    clicked: boolean;



    init(): void {
        var _self = this;

            _self.clicked = false;

            var offset = 250;
            var duration = 300;
            _self.$(".back-to-top").fadeOut(duration);
            _self.$(window).scroll(function () {
                if (_self.$(window).scrollTop() > offset) {
                    _self.$(".back-to-top").fadeIn(duration);
                } else {
                    _self.$(".back-to-top").fadeOut(duration);
                }
                var scrollHeight = _self.$(document).height();
                var scrollPosition = _self.$(window).height() + _self.$(window).scrollTop();
                if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
                    _self.$(".back-to-top i").css("color", "#FFF");
                } else {
                    _self.$(".back-to-top i").css("color", "#99b1db");
                }
            });

        _self.$(".back-to-top").click( e => {
            e.preventDefault();
            if (!_self.clicked) {
                _self.clicked = true;
                this.$("html, body").animate({ scrollTop: 0 }, duration).promise().then(() => { _self.clicked = false; });
            }
            return false;
        });
 
    }

    tab(id: string): void {
        var _self = this;
        if (id) {
            _self.$("a[href$='" + id + "']").tab('show');
        }

    }

    scroll(id: string): void {
        var _self = this;
        if (id) {
         var top = _self.$(`#${id}`).offset().top;
        _self.$('html,body').animate({ scrollTop: top }, 'slow');
        }

    }

    print(): void {

        setTimeout(function () { window.print(); }, 500);
        window.onfocus = function () { setTimeout(function () { window.close(); }, 100); }
    }


    carousel(id: string) {
        var _self = this;
        _self.$(id).carousel("cycle");
    }

    

    getDate() {
        return new Date();
    }


    constructor(@Inject(JQ_TOKEN) public  $: any) {

    }





}

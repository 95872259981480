﻿import { Component, AfterViewInit, Inject, OnInit  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, MayoService } from "../shared/common/index";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/brain-health.svg";
import { FamilyTreeService } from "./family-tree/family-tree.service";

@Component({
    selector: 'tools-component',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.scss']
})


export class ToolsComponent implements AfterViewInit, OnInit  {

    url: any;

    public constructor(private titleService: Title, private router: Router, private mayo: MayoService, private service: FamilyTreeService,
        @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {
        this.titleService.setTitle("FH Family Share - Tools");


        this.router.events.subscribe(s => {
   
        if (s instanceof NavigationEnd) {
                this.mayo.tab(this.router.parseUrl(this.router.url).fragment);
            }
        });
        
    }

    ngOnInit(): void {
        
        this.service.getFamilyTreeUrl().subscribe(a => {
            this.url = a.Url;
        });
    }

    ngAfterViewInit(): void {
        this.mayo.init();

    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-learn-additional-resources',
  templateUrl: './learn-additional-resources.component.html',
  styleUrls: ['./learn-additional-resources.component.scss']
})
export class LearnAdditionalResourcesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

﻿import { Component, Input } from '@angular/core';
import "!svg-sprite-loader!element.ui/modules/assets/icons/material-design/print2.svg";

@Component({
    selector: 'page-title-component',
    templateUrl: './page-title.component.html',
})

export class PageTitleComponent {
    @Input() title: string;
    @Input() print: boolean;
    constructor() {

    }
}
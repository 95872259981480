import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-learn-fh-considerations',
  templateUrl: './learn-fh-considerations.component.html',
  styleUrls: ['./learn-fh-considerations.component.scss']
})
export class LearnFhConsiderationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

﻿import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HeaderComponent } from './header.component'
import { FooterComponent } from './footer.component'
import { PageTitleComponent } from './page-title.component'


@NgModule({
    imports: [BrowserModule,
        RouterModule],
    exports: [HeaderComponent, FooterComponent, PageTitleComponent],
    declarations: [HeaderComponent, FooterComponent, PageTitleComponent]
})
export class SharedModule {

}
﻿
import { Component, Input } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from './../environments/environment';

@Component({
    selector: 'header-component',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
    @Input() application: string;
    @Input() secondary: string;
    constructor(private meta: Meta) {
       
    }
    
    appointmentClicked(){
    console.log('environment prod: ' + environment.production);
    if(environment.production){
        window.open("https://www.mayoclinic.org/forms/us-resident-appointment", "_blank");
    }
    else{
        window.open("https://test.onlineservices.mayoclinic.org/NewAppointments/", "_blank");
    }
}

}
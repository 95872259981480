﻿import { Component, AfterViewInit, Inject, EventEmitter, Output, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, MayoService } from "../shared/common/index";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/brain-health.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/person-success.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/family-history.svg";

@Component({
    selector: 'home-component',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements AfterViewInit {

    slides: any[];
    customOptions: any = {
        loop: true,
        margin: 10,
        autoplay:true,
        responsiveClass: true,
        // navText: ['Previous', 'Next'],
        navText : ['<i class="fa fa-angle-left" aria-hidden="true" style="color:black"></i>','<i class="fa fa-angle-right" aria-hidden="true" style="color:black"></i>'],
        responsive: {
          0: {
           items: 1
         },
       },
       nav: true
      }

      slidesStore = [       
        {
            id: "1",
            src: "./dist/assets/images/home2.jpg",
            title: "If you have high cholesterol and a family history of early-onset heart disease, FH could be the cause."
        },
        {
            id: "2",
            src: "./dist/assets/images/home_img2.jpg",
            title: "1 out of every 250 people has FH, however only 10% are diagnosed."
        },
        {
            id: "3",
            src: "./dist/assets/images/home3.jpg",
            title: "Risk of heart disease due to FH can be successfully managed with lifestyle modifications and medications."
        },
        {
            id: "4",
            src: "./dist/assets/images/home_img4.jpg",
            title: "Get screened for FH to reduce your risk of early-onset heart disease."
        }
    ];

    public constructor(
        private titleService: Title, private router: Router, private mayo: MayoService,
        @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {

        this.titleService.setTitle("FH Family Share - Home");

    }

    ngAfterViewInit(): void {
        this.mayo.init();
        this.mayo.carousel("#home-carousel");
    }
}
﻿import { Component, AfterViewInit, OnInit } from "@angular/core";
import { DomSanitizer} from "@angular/platform-browser";
import { FamilyTreeService } from "./family-tree.service";

@Component({
    selector: "family-tree-container",
    templateUrl: "tree-container.component.html",
    styleUrls: ["tree-container.component.css"]
})


export class FamilyTreeContainerComponent implements OnInit {


    url: any;
    loading: boolean;


    public constructor(
        private sanitizer: DomSanitizer,
        private service: FamilyTreeService) {
        this.loading = true;
    }

    ngOnInit(): void {
            this.service.getFamilyTreeUrl().subscribe(a => {
                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(a.Url);
                this.loading = false;
            });
    }
}
﻿import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FamilyTreeService } from "./family-tree.service";
import { FamilyTreeContainerComponent } from './tree-container.component';



@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [FamilyTreeContainerComponent],
    exports: [FamilyTreeContainerComponent],
    providers: [FamilyTreeService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FamilyTreeModule { }

﻿import { Component, OnInit } from '@angular/core';
import { environment } from './../environments/environment';

@Component({
    selector: 'app-root',
    template: `
<header-component application="FH Family Share" secondary="Familial Hypercholesterolemia"></header-component>
<router-outlet></router-outlet>
<footer-component></footer-component>
`
})

export class LayoutComponent {
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-learn-what-is-fh',
  templateUrl: './learn-what-is-fh.component.html',
  styleUrls: ['./learn-what-is-fh.component.scss']
})
export class LearnWhatIsFhComponent implements OnInit {

  isCollapsed: boolean;
  @Output() collapsedEvent = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
    this.isCollapsed = true;
    this.collapsedEvent.emit(this.isCollapsed);
  }

}

import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/external-link-alt.svg";
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';
import { JQ_TOKEN, MayoService, Toastr, TOASTR_TOKEN } from 'src/shared/common';
import { filter, map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-learn-layout',
  templateUrl: './learn-layout.component.html',
  styleUrls: ['./learn-layout.component.scss']
})
export class LearnLayoutComponent implements OnInit {

  isCollapsed: boolean;
  fh_routes = ["what-is-fh", "fh-considerations", "fh-causes", "fh-risks", "fh-symptoms",
  "genetics", "genetic-testing-faqs", "treatment", "additional-resources"];

  public constructor(private titleService: Title, private router: Router, private mayo: MayoService,
    private route: ActivatedRoute, @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {
    this.titleService.setTitle("FH Family Share - Learn");

    this.router.events
      .pipe(
        filter(
          (event) => !!(event instanceof NavigationEnd && event.urlAfterRedirects) 
        ),
        map((event) =>  (<NavigationEnd> event).urlAfterRedirects),
        startWith(router.url)
      )
      .subscribe((pageUrl) => {
        console.log('.......' + pageUrl);
        console.log('.Before..this.isCollapsed..'+this.isCollapsed);
     
        this.isCollapsed = !!this.fh_routes.find(
          (url) => pageUrl.indexOf(url) != -1
        );  
       
       
        console.log('.After..this.isCollapsed..'+this.isCollapsed);
      });
    
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.mayo.init();
  }
}

﻿import { Component,  OnInit,  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MayoService } from "./common/index";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/facebook-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/pinterest-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/twitter-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/youtube-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/instagram.svg";


@Component({
    selector: 'footer-component',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [DatePipe]
})

export class FooterComponent implements OnInit {
    year: string;
    datePipe: DatePipe;
    constructor(private _datePipe: DatePipe,private mayo: MayoService) {
    
    }

    ngOnInit(): void {
        var now = this.mayo.getDate();
        this.year = this._datePipe.transform(now, "yyyy");
    }

}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-learn-genetics.component',
  templateUrl: './learn-genetics.component.html',
  styleUrls: ['./learn-genetics.component.scss']
})
export class LearnGeneticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}

import { Component, OnInit, AfterViewInit, Inject} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, MayoService } from "../shared/common/index";
@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss']
})


export class CustomerSupportComponent implements OnInit {

  public constructor(
    private titleService: Title, private router: Router, private mayo: MayoService,
    @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any, private viewportScroller: ViewportScroller) {

    this.titleService.setTitle("FH Family Share - CustomerSupport");
}

  ngOnInit() {
  }

ngAfterViewInit(): void {
    this.mayo.init();
}

  onClickScroll(elementId: string): void{
  	this.viewportScroller.scrollToAnchor(elementId);
}
}

﻿import { Component, AfterViewInit, Inject, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, MayoService } from "../shared/common/index";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/external-link-alt.svg";
import { LearnGeneticTestingFaqsComponent } from './learn-genetic-testing-faqs/learn-genetic-testing-faqs.component';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'learn-component',
    templateUrl: './learn.component.html',
    styleUrls: ['./learn.component.scss']
})


export class LearnComponent implements AfterViewInit, OnInit  {
     
    public constructor(private titleService: Title, private router: Router, private mayo: MayoService,
        private route: ActivatedRoute, @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {
        this.titleService.setTitle("FH Family Share - Learn");          
    }

    ngOnInit(): void {
       
    }

    ngAfterViewInit(): void {
        this.mayo.init();

    }
}
import { Component, Input, ViewChild, ElementRef, Inject } from "@angular/core";
import { JQ_TOKEN } from "./jQuery.service";

@Component({
    selector: 'simple-modal',
    template: `
<div id="{{elementId}}" #modalcontainer class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-content></ng-content>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn mcui-btn" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
  `,
    styles: []
})
export class SimpleModalComponent {
    @Input() title: string;
    @Input() elementId: string;
    @Input() closeOnBodyClick: string;
    @ViewChild('modalcontainer') containerEl: ElementRef;

    constructor(@Inject(JQ_TOKEN) public $: any) {

    }

    closeModal() {
        var _self = this;
        if (_self.closeOnBodyClick === "true") {
            _self.$(_self.containerEl.nativeElement).modal('hide');
        }
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-learn-fh-title',
  templateUrl: './learn-fh-title.component.html',
  styleUrls: ['./learn-fh-title.component.scss']
})
export class LearnFhTitleComponent implements OnInit {

  isCollapsed: boolean;
  @Output() collapsedEvent = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
    this.isCollapsed = true;
    this.collapsedEvent.emit(this.isCollapsed);
  }

}

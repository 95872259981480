﻿import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, MayoService } from "../shared/common/index";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/person-success.svg";

import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/genetics.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/comment-health-tip.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/brain-health.svg";


@Component({
    selector: 'share-component',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})


export class ShareComponent implements AfterViewInit {
    [x: string]: any;
    letterText: string = '';
    
    fromNameFam: string = '';
    fromNameFamReplaceText: string = '';
    fromNameProv: string = '';
    fromNameProvReplaceText: string = '';

    geneNameFam: string = '';
    geneNameFamReplaceText: string = '';
    geneNameProv: string = '';
    geneNameProvReplaceText: string = '';

    variantNameFam: string = '';
    variantNameFamReplaceText: string = '';
    variantNameProv: string = '';
    variantNameProvReplaceText: string = '';

    labNameFam: string = '';
    labNameFamReplaceText: string = '';
    labNameProv: string = '';
    labNameProvReplaceText: string = '';

    accessionCodeFam: string = '';
    accessionCodeFamReplaceText: string = '';
    accessionCodeProv: string = '';
    accessionCodeProvReplaceText: string = '';

    isFamilyLetterSelected: boolean = true;
    isHealthCareProviderLetterSelected: boolean = false;
    togglePrint: boolean = false;
    @ViewChild("printableText")
    documentEditor: ShareComponent;


    public constructor(private titleService: Title, private router: Router, private mayo: MayoService,
        @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {
        this.titleService.setTitle("FH Family Share - Share");

    }
    
    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.mayo.init();

    }

    showFamilyLetter(){
        this.isFamilyLetterSelected = true;
        this.isHealthCareProviderLetterSelected = false;
    }
   
    showHealthCareProviderLetter(){
        this.isFamilyLetterSelected = false;
        this.isHealthCareProviderLetterSelected = true;
    }

    printText(){
        this.togglePrint = true;
        setTimeout(()=>{
            window.print();
            setTimeout(()=>{
                this.togglePrint = false;
            },100)
            
        },100)
    } 

    getGeneStyle(){
       let geneStyle;
       geneStyle  ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4',
        'font-style': this.togglePrint? 'italic': 'normal',
        'min-width':this.togglePrint?'100px':'100px',
        'width': 'auto'
        };
        if (this.geneNameFam.length === 0 && this.geneNameProv.length === 0) {
        geneStyle  ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4'     
        };
    }
    return geneStyle;
    }
    getAccessionCodeStyle(){
        let codeStyle;
        codeStyle ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4',
        'font-style': this.togglePrint? 'italic': 'normal',
        'min-width':this.togglePrint?'140px':'140px',
        'width': 'auto'
        };
        if (this.accessionCodeFam.length === 0 && this.accessionCodeProv.length === 0) {
        codeStyle ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4'  
        };
    }
        return codeStyle;

    }
    getVariantStyle(){
        let variantStyle; 
        variantStyle ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4',
        'font-style': this.togglePrint? 'italic': 'normal',
        'min-width':this.togglePrint?'110px':'110px',
        'width': 'auto'
        };

        if (this.variantNameFam.length === 0 && this.variantNameProv.length === 0) {
        variantStyle ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4'       
        };
        }
        return variantStyle;
    }
    getLabStyle(){
        let labStyle;

        labStyle ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4',
        'font-style': this.togglePrint? 'italic': 'normal',
        'min-width':this.togglePrint?'170px':'170px',
        'width': 'auto'
        };
        if (this.labNameFam.length === 0 && this.labNameProv.length === 0) {
        labStyle ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4'    
        }; 
        }
        return labStyle;

    }
    getFromNameStyle(){
        let nameStyle;
        nameStyle  ={  
        'border':this.togglePrint?'0px none':'1px solid #D4D4D4',
        'font-style': this.togglePrint? 'italic': 'normal'
        };
        if (this.fromNameFam.length === 0 && this.fromNameProv.length === 0) {
        nameStyle  ={  
            'border':this.togglePrint?'0px none':'1px solid #D4D4D4'
        };
        }
        return nameStyle;

    }
    onCopyAndShareLetterButtonClick(){

        if(this.isHealthCareProviderLetterSelected){

            if (this.fromNameProv === "") {
                this.fromNameProvReplaceText = "(Name)";
            }
            else {
                this.fromNameProvReplaceText = this.fromNameProv;
            }

            if (this.geneNameProv === "") {
                this.geneNameProvReplaceText = "(gene name)";
            }
            else {
                this.geneNameProvReplaceText = this.geneNameProv;
            }

            if (this.variantNameProv === "") {
                this.variantNameProvReplaceText = "(variant name)";
            }
            else {
                this.variantNameProvReplaceText = this.variantNameProv;
            }

            if (this.labNameProv === "") {
                this.labNameProvReplaceText = "(genetic testing lab)";
            }
            else {
                this.labNameProvReplaceText = this.labNameProv;
            }

            if (this.accessionCodeProv === "") {
                this.accessionCodeProvReplaceText = "(accessionCode)";
            }
            else {
                this.accessionCodeProvReplaceText = this.accessionCodeProv;
            }

            this.letterText = "mailto:?subject=Familial%20Hypercholesterolemia&body=Dear%20Healthcare%20Provider,%0D%0A%0D%0AI%20have%20been%20found%20to%20have%20an%20inherited%20condition%20called%20Familial%20Hypercholesterolemia%20(FH).%20I%20had%20genetic%20testing%20done%20which%20identified%20that%20I%20carry%20a%20pathogenic%20mutation%20in%20the%20"
            +this.geneNameProvReplaceText+
            "%20gene%20called%20"
            +this.variantNameProvReplaceText+"."+
            "%20This%20testing%20was%20performed%20at%20the%20"
            +this.labNameProvReplaceText+
            "%20with%20the%20following%20"
            +this.accessionCodeProvReplaceText
            +"%20accession%20code."
            +"%0D%0AFH%20is%20a%20genetic%20disorder%20that%20leads%20to%20high%20cholesterol%20levels%20in%20the%20blood%20and%20up%20to%20a%2020-fold%20increased%20risk%20for%20premature%20heart%20disease%20(including%20heart%20attack),%20if%20left%20untreated.%20One%20can%20have%20FH%20without%20any%20physical%20signs%20or%20symptoms.%20FH%20needs%20to%20be%20treated%20through%20medication%20and%20lifestyle%20changes.%20I%20would%20like%20to%20discuss%20my%20diagnosis%20of%20FH%20further%20with%20you.%0D%0AThank%20You.%0D%0A"
            +this.fromNameProvReplaceText;
        }

        if (this.isFamilyLetterSelected) {
            if (this.fromNameFam === "") {
                this.fromNameFamReplaceText = "(Name)";
            }
            else {
                this.fromNameFamReplaceText = this.fromNameFam;
            }

            if (this.geneNameFam === "") {
                this.geneNameFamReplaceText = "(gene name)";
            }
            else {
                this.geneNameFamReplaceText = this.geneNameFam;
            }

            if (this.variantNameFam === "") {
                this.variantNameFamReplaceText = "(variant name)";
            }
            else {
                this.variantNameFamReplaceText = this.variantNameFam;
            }

            if (this.labNameFam === "") {
                this.labNameFamReplaceText = "(genetic testing lab)";
            }
            else {
                this.labNameFamReplaceText = this.labNameFam;
            }

            if (this.accessionCodeFam === "") {
                this.accessionCodeFamReplaceText = "(accessionCode)";
            }
            else {
                this.accessionCodeFamReplaceText = this.accessionCodeFam;
            }

            this.letterText = "mailto:?subject=Familial%20Hypercholesterolemia&body=Dear%20Family%20Member,%0D%0A%0D%0AI%20have%20been%20diagnosed%20with%20Familial%20Hypercholesterolemia%20(FH),%20a%20genetic%20disorder%20that%20leads%20to%20high%20cholesterol%20levels%20in%20the%20blood%20if%20left%20untreated.%20FH%20can%20increase%20the%20risk%20for%20premature%20heart%20disease%20(including%20heart%20attack)%20by%20up%20to%2020-fold.%0D%0ABecause%20this%20condition%20runs%20in%20families,%20you%20may%20also%20be%20at%20risk%20for%20FH.%20The%20good%20news%20is%20that%20heart%20disease%20due%20to%20FH%20can%20be%20prevented%20by%20medications%20that%20lower%20cholesterol%20levels.%20%0D%0AMy%20genetic%20test%20identified%20a%20disease-causing%20variant%20"
            +this.variantNameFamReplaceText+
            "%20in%20the%20"
            +this.geneNameFamReplaceText
            +"%20gene.%20The%20test%20was%20performed%20at%20"
            +this.labNameFamReplaceText+
            ",%20with%20the%20following%20"
            +this.accessionCodeFamReplaceText
            +"%20accession%20code."
            +"%20Your%20healthcare%20provider%20can%20order%20a%20genetic%20test%20to%20determine%20whether%20you%20carry%20the%20same%20variant%20that%20leads%20to%20FH.%0D%0AIt%20might%20be%20helpful%20for%20you%20to%20meet%20with%20a%20healthcare%20provider%20to%20discuss%20this%20further.%20The%20FH%20Clinic%20at%20Mayo%20Clinic%20in%20Rochester,%20MN,%20can%20be%20reached%20at%20(507)%20284-4443%20for%20an%20appointment.The%20fhfamilyshare.mayoclinic.org%20website,%20developed%20by%20Mayo%20Clinic,%20provides%20useful%20information%20about%20FH,%20and%20includes%20contact%20information%20for%20Mayo%20Clinic%20locations%20in%20Florida,%20Arizona,%20and%20the%20Mayo%20Clinic%20Health%20System.%0D%0AYou%20can%20learn%20more%20about%20genetic%20testing%20or%20schedule%20an%20appointment%20with%20a%20genetic%20counselor%20at%20Mayo%20Clinic%20by%20visiting%20www.mayoclinic.org/departments-centers/clinical-genomics/services%20or%20www.findageneticcounselor.com%20for%20a%20list%20of%20genetic%20counselors%20in%20your%20area.%0AI%20hope%20this%20information%20is%20helpful.%0D%0A%0D%0AThank%20You.%0D%0A"   
            +this.fromNameFamReplaceText;
        }
    }
}

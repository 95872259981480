import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {

  constructor(private eleRef: ElementRef) { }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    Array.prototype.slice.call(document.querySelectorAll("li")).forEach((item) => {
      item.style.listStyleType = "none";
    });
    event.target.style.listStyleType = "disc";
    event.preventDefault();
    event.stopPropagation();
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-learn-genetic-testing-faqs',
  templateUrl: './learn-genetic-testing-faqs.component.html',
  styleUrls: ['./learn-genetic-testing-faqs.component.scss']
})
export class LearnGeneticTestingFaqsComponent implements OnInit {

  isFaq1Collapsed: boolean = false;
  isFaq2Collapsed: boolean = false;
  isFaq3Collapsed: boolean = false;
  isFaq4Collapsed: boolean = false;
  isFaq5Collapsed: boolean = false;
  isFaq6Collapsed: boolean = false;
  isFaq7Collapsed: boolean = false;
  isFaq8Collapsed: boolean = false;
  isFaq9Collapsed: boolean = false;
  showFaq1: boolean = true;
  showFaq2: boolean = true;
  showFaq3: boolean = true;
  showFaq4: boolean = true;
  showFaq5: boolean = true;
  showFaq6: boolean = true;
  showFaq7: boolean = true;
  showFaq8: boolean = true;
  showFaq9: boolean = true;
  
  constructor() { }

  ngOnInit() {
  }
}

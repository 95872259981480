﻿import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UrlDto } from "./family-tree.models";

export interface IFamilyTreeService {

}

@Injectable()
export class FamilyTreeService implements IFamilyTreeService {
    apiBaseUri: string;



    constructor(private http: HttpClient) {
        this.apiBaseUri = "/api/v1/";
        this.http = http;
    }

    public getFamilyTreeUrl(): Observable<UrlDto> {
        return this.http.get<UrlDto>(this.apiBaseUri + "Core/Config/FamilyTree");
    }




}
﻿import { Component, AfterViewInit, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, MayoService } from "../shared/common/index";

import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/video-visit.svg";

@Component({
    selector: 'print-component',
    template: `
        <learn-component></learn-component>
        <resources-component></resources-component>
  `
})


export class PrintComponent implements AfterViewInit, OnInit {

    public constructor(private titleService: Title, private router: Router, private mayo: MayoService,
        @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {
        this.titleService.setTitle("FH Family Share - Print");

    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        
    this.mayo.print();

    }
}